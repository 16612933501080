// https://github.com/facebook/create-react-app/tree/master/packages/react-app-polyfill
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';

// this line will present IE 11 from producing error (and working?)
import 'react-app-polyfill/stable';
// https://github.com/facebook/create-react-app/issues/9906
// this is currently borked -> import 'react-app-polyfill/stable'
// so use core-js.. which might be the "right" way now per
// https://reactjs.org/docs/javascript-environment-requirements.html
import 'core-js/features/promise';
//import 'core-js/es/map';
//import 'core-js/es/set';
import 'raf/polyfill';
//import 'core-js/features/set/map';

import React from "react";
import {hydrate, render} from "react-dom";
//import { render } from 'react-snapshot';
// ReactDOM
import App from "./App";
import Variable from "./Variable";
import registerServiceWorker from "./registerServiceWorker";
import 'autotrack/autotrack.js';
import ReactGA from "react-ga";
//import MuiThemeProvider from "material-ui/styles/MuiThemeProvider"; 


//import "./my-react-accessible-accordion.css";

//import "./index.css";

// removed, as claimed that most browsser solve it: https://www.npmjs.com/package/react-tap-event-plugin
/*
import injectTapEventPlugin from "react-tap-event-plugin";
injectTapEventPlugin();
*/
if (localStorage.getItem("language") == null) {
  localStorage.setItem("language", "English");
  console.log(
    "index.localStorage.language: " + localStorage.getItem("language")
  );
}

// for development: UA-106473517-3
//var gaID = "UA-106473517-3";

// for beta: UA-106473517-2
var gaID = "UA-106473517-2";

// for production
//var gaID = "UA-106473517-1";

ReactGA.initialize(gaID, { debug: true });
ReactGA.plugin.require('cleanUrlTracker');

// Enables declarative event tracking, via HTML attributes in the markup.
// need to declare for each interaction and each DOM element
ReactGA.plugin.require('eventTracker', {
  attributePrefix: 'data-', 
  events: ['click', 'auxclick', 'contextmenu']
}
);

// have some reports, but need for fine-tuned
ReactGA.plugin.require('maxScrollTracker', {
  sessionTimeout: 4 * 60,
  increaseThreshold: 10
  //timeZone: 'America/Los_Angeles',
});


// seems working
ReactGA.plugin.require('outboundLinkTracker');

// got the footer, but could check whether reflection_form is working
ReactGA.plugin.require('impressionTracker', {
  elements: ['headerComponent', 'footerComponent', 'resultPage', 'resultPage_reflection_form', 'resultPage_graph', 'homePage_tab1', 'homePage_tab2', 'homePage_tab3']
  //,rootMargin: '-20px 0'
  }
);

// in forgraound or background
ReactGA.plugin.require('pageVisibilityTracker');



// keep it just because curious

// Enables tracking media query matching and media query changes.
// for resonsive page change
ReactGA.plugin.require('mediaQueryTracker');
ReactGA.plugin.require('outboundFormTracker');


// no need?
//ReactGA.plugin.require('urlChangeTracker');
//ReactGA.plugin.require('socialWidgetTracker');



function logPageView() {
  console.log("logPageView: " + window.location.pathname + ",\n" + window.location.search);
  ReactGA.set({ page: window.location.pathname + window.location.search });
  ReactGA.pageview(window.location.pathname + window.location.search);
} 

//registerServiceWorker();

/*
<MuiThemeProvider>
    <App gAnalytics={logPageView} />
  </MuiThemeProvider>
*/
//<App gAnalytics={logPageView} />


// without react-snapshot
/*
ReactDOM.render(
  <App gAnalytics={logPageView} ReactGA={ReactGA} />,
  document.getElementById("root")
);
*/


// using react-snapshot
// not working
/*
const rootElement = document.getElementById("root");
render(<App gAnalytics={logPageView} ReactGA={ReactGA} />, rootElement);
*/
// using react-snap

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App gAnalytics={logPageView} ReactGA={ReactGA} />, rootElement);
} else {
  render(<App gAnalytics={logPageView} ReactGA={ReactGA} />, rootElement);
}
