import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import MyPager from "../components/MyPager";
import MyCalculation from "../utils/MyCalculation";


export default class TraditionalChinese {
  constructor() {
    console.log("TraditionalChinese: constructor");
  }
  static content =  {
    site: {
      loading_message: "下載中...",
      ie_message: <div className="lead">看起來您正在使用 Internet Explorer（IE）。 我們的網站最適合以 Chrome 瀏覽，並且將來可能無法支持 IE。 請考慮使用 Chrome，Edge 或 Firefox 以獲得最佳效果。<br /><br />
      您可以在此處下載 Chrome： <a
                  target="_blank"
                  href="https://www.google.com/chrome/"
                  rel="noopener noreferrer"
                >
                  Chrome
                </a>
      </div>,
      share_button_label: {
        share: "分享此頁面",
        hide: "隱藏分享工具"
      }
    },
    benefitHarmPage: {
      banner_image_source: "/image/FLIKR+Yale+Rosen+CC2.0.jpg",
      banner_title: "肺癌篩檢(檢查)",
      title: "您應該了解的有關肺癌篩檢的知識",
      benefit_harm_content: {
        benefit: {
          title: "篩檢有什麼好處？",
          content:
            "對於有資格進行篩檢並決定接受篩檢的人，及早發現癌症的機會更高。 儘早發現癌症通常意味著可以提供更成功的治療方法。 最近的一項研究表明：在六年半之後，符合篩檢條件的人中，接受電腦斷層掃描（CT）篩檢的人比未接受篩檢的人死於肺癌的可能性要低 20％。"
        },
        harm: {
          title: "篩檢的危害是什麼？",
          content: (
            <div>
              <p>
              肺癌篩檢僅有助於發現已經存在的癌症。 它不能預防癌症。 預防癌症的最佳方法是停止吸菸（如果您尚未這樣做的話）。與傳統的胸腔電腦斷層掃描相比，用於肺癌篩檢的電腦斷層掃描使用的輻射劑量更低：最多可減少 90％。肺癌篩檢的輻射劑量等於您日常環境中約 6 個月的輻射量。因此，要從一次成人檢查中發展出致命肺癌的額外風險是極低的。對總輻射劑量感興趣的話，可以訪問&nbsp;
                <a
                  target="_blank"
                  href="http://www.xrayrisk.com/calculator/calculator-normal-studies.php?id=68"
                  rel="noopener noreferrer"
                >
                  這個網站
                </a>
                。篩檢的其他潛在危害包括：假警訊，過度治療，更多測試和侵入性醫療程序。
              </p>
            </div>
          )
        }
      },
      sample_list: [
        {
          title: "假警訊",
          content: (
            <div>
              <p>
              篩檢的原理是通過發現肺結節來進行，其中一些結節是癌症，但是在低劑量電腦斷層掃描（CT）中檢測到的大多數結節<strong>不是</strong>癌症。肺結節是圓形或橢圓形的斑點。根據當前的結節管理指南，肺癌的假陽性率很低，約為10-12％ (
                <a
                  target="_blank"
                  href="https://pubmed.ncbi.nlm.nih.gov/29632062/"
                  rel="noopener noreferrer"
                >
                  McKee et al.
                </a>
                ). 
                錯誤的假陽性結果可能會引起焦慮。 但是，若能了解到『CT篩檢所發現的大多數肺結節<strong>不</strong>代表癌症』，有助於大多數人在等待後續檢查時緩解焦慮。
              </p>
            </div>
          )
        },
        {
          title: "過度治療（和過度診斷）",
          content: (
            <div>
              <p>
              有時候，篩檢會發現被斷定為癌症的結節，但這些結節其實不會造成任何問題。 這樣的診斷可能會導致後續不必要的治療，例如手術，化學療法和放射治療，也就是所謂的過度治療。 過度治療可能會導致副作用，例如使您感到不適。
              </p>
            </div>
          )
        },
        {
          title: "持續篩檢",
          content: (
            <div>
              <p>
              為了確認結節不是癌症，通常建議進行後續掃描以監測變化。 您的醫生會建議每間隔一段適當的時間來進行隨訪，監測被檢測出的肺結節。 通常會建議每 6 個月至 12 個月進行一次後續追蹤。
              </p>
            </div>
          )
        },
        {
          title: "侵入性檢查",
          content: (
            <div>
              <p>
              有時候，得到肺癌篩檢篩結果後需要進行進一步的其他檢查，其中包括侵入性檢查（切片檢查或是手術）。 這些測試是有風險的，即便檢查後確認並非癌症，還是有可能對身體造成傷害。侵入性手術可能導致併發症，輕微的併發症如出血和感染，嚴重的併發症如肺萎縮甚至危及性命。
              </p>
            </div>
          )
        }
      ],
      more_info: (
        <p>
          有關更多詳細的資訊，您可以造訪國家癌症研究所（National Cancer Institute）網站整理的
          <a
            href={"https://www.cancer.gov/types/lung/research/nlst-qa"}
            target="_blank"
            rel="noopener noreferrer"
          >
            {"常見問題集"}
          </a>，該網頁對全國性的肺部篩檢試驗結果有詳細的說明，或是 <a
            href={"https://screenyourlungs.org/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            {"screenyourlungs.org"}
          </a> 網站。
        </p>
      ),
      image_info: {
        head: "Image: ",
        src: "https://www.flickr.com/photos/pulmonary_pathology/",
        text: "Yale Rosen/Flickr. CC BY-SA 2.0."
      }
    },
    homePage: {
      banner_title: "肺癌篩檢(檢查)",
      banner_content: "我應該接受篩檢嗎？",
      banner_button_label: "點擊了解更多",
      banner_button_link: "/TraditionalChinese/how-is-screening-done",
      two_column_title: "我們能幫助你。",
      two_column_sub_title:
        "要決定是否進行電腦斷層掃描來篩檢肺癌並不容易。在這裡，醫生提供了最新資訊，以幫助您做出明智的選擇。",
      //"Deciding whether or not to go through lung cancer CT screening is not easy. We have put together a road map of what you should know based on where you are in this important decision.",
      two_column_content: {
        first: {
          title: "誰有資格進行肺癌篩檢？",
          sub_title: (
            <div>
              <p>
              美國預防服務工作小組 (The US Preventive Services Task Force) 最近將肺癌篩檢的資格指南更新為以下內容：
              </p>
              <ul>
                <li>年齡在 50 至 80 歲之間</li>
                <li>在過去 15 年內曾經吸菸</li>
                <li>
                  20 包年 (pack-years) (參照{" "}
                  <Link
                    to={"/TraditionalChinese/pack-year-calculator"}
                    
                    // not working
                    //to={this.getRouteByPageAndLanguage("CalculatorPage", "English")}
                    style={{ width: "100%", textDecoration: "none" }}
                    data-on="click"
                    data-event-category="HomePage"
                    data-event-action={"/TraditionalChinese/pack-year-calculator"}
                  >
                    包年計算器
                  </Link>
                  )
                </li>
              </ul>
              <p>
              然而，聯邦老人醫療保險以及聯邦醫療保險的給付指南目前的條件（截至2022年2月10日）是下列項目:
              </p>
              <ul>
                <li>50-77歲</li>
                <li>過去 15 年內吸菸</li>
                <li>
                20 包年 (參閱{" "}
                  <Link
                    to={"/TraditionalChinese/pack-year-calculator"}
                    
                    // not working
                    //to={this.getRouteByPageAndLanguage("CalculatorPage", "English")}
                    style={{ width: "100%", textDecoration: "none" }}
                    data-on="click"
                    data-event-category="HomePage"
                    data-event-action={"/TraditionalChinese/pack-year-calculator"}
                  >
                    包年計算器
                  </Link>
                  )
                </li>
              </ul>
            
            </div>
          ),
          image: "/image/questioningDude.jpg",
          button_label: "了解更多",
          link: "/TraditionalChinese/how-is-screening-done"
        },
        second: {
          title: "肺癌風險計算器",
          sub_title:
            "想知道是否應該接受篩檢嗎？請使用我們的計算器查看您的個人化肺癌風險。",
          image: "/image/SIScalculator.jpg",
          button_label: "使用肺癌風險計算器",
          link: "/TraditionalChinese/lung-cancer-risk-calculator"
        }
      },
      time_navigation: [
        {
          title: "1. 為什麼要做肺癌篩檢(檢查)？",
          items: [
            {
              title: "我為什麼要考慮這件事？",
              content: (
                <div>
                  <p>
                    篩檢可以幫助患者在早期發現肺癌，若能及時接受治療，成功率也較高。如果不進行篩檢，通常會在肺癌晚期才發現。{" "}
                  </p>
                  <div className="float-right">
                    <MyPager
                      link={"/TraditionalChinese/how-is-screening-done"}
                      label={"了解更多"}
                      arrowDirection={"right"}
                    ></MyPager>
                  </div>
                </div>
              )
            },
            {
              title: "肺癌的警訊是什麼？",
              content: (
                <p>
                  下列症狀都與肺癌有關，例如疼痛、非預期的體重減輕、咳血，或者咳嗽不止，若你是吸菸者更要特別注意這些症狀。
                  {" "}
                  <strong>
                  但是肺癌篩檢並不是要提供給已有症狀的患者
                  </strong>
                  。如果您有任何讓您感到擔憂的症狀，請和您的醫生討論。
                </p>
              )
            },
            {
              title: "什麼是肺癌電腦斷層篩檢？",
              content: (
                <div>
                  <p>
                    肺癌篩檢包含胸部電腦斷層掃描（CT)。進行電腦斷層掃描時，您需要靜躺著穿過一台環型機器，過程中您的胸腔影像會透過X光以及電腦拍攝、紀錄下來。{" "}
                  </p>
                  <div className="float-right">
                    <MyPager
                      link={"/TraditionalChinese/how-is-screening-done"}
                      label={"了解更多"}
                      arrowDirection={"right"}
                    ></MyPager>
                  </div>
                </div>
              )
            }
          ]
        },
        {
          title: "2. 花費",
          items: [
            {
              title: "花費",
              content: (
                <div>
                  <p>
                  如果您沒有保險，那麼初步的肺癌篩檢可能要自費100到400美金。您可以點擊下方按鈕前往保險頁面，以查看您的健康保險是否有涵蓋肺癌篩檢。
                  </p>
                  <p>
                    <div className="float-right">
                      <MyPager
                        link={"/TraditionalChinese/coverage"}
                        label={"了解更多"}
                        arrowDirection={"right"}
                      ></MyPager>
                    </div>
                  </p>
                </div>
              )
            },
            {
              title: "我可以到哪裡做篩檢？",

              content: (
                <div>
                  <p>
                    建議您到「美國放射學會」認證的機構進行肺癌篩檢。 點擊&nbsp;
                    <a
                      target="_blank"
                      href="https://www.acr.org/Clinical-Resources/Lung-Cancer-Screening-Resources/LCS-Locator-Tool"
                      rel="noopener noreferrer"
                    >
                      此處
                    </a>
                    &nbsp;查看通過認證機構列表.
                  </p>
                </div>
              )
            }
          ]
        },
        {
          title: "3. 其他需要考量的事項",
          items: [
            {
              title: "肺癌篩檢不是一次性的事情",
              content: (
                <div>
                  <p>
                    肺癌篩檢並非一次性的，是需要定期篩檢的。只要您還符合篩檢資格，就需要每年安排一次篩檢，才能最大化篩檢的效益。另外，篩檢後若發現肺結節，則需要在三至六個月內以電腦斷層掃描進行後續追蹤。{" "}
                  </p>
                </div>
              )
            },

            {
              title: "關於潛在的風險",
              content: (
                <div>
                  <p>
                    您需要考慮到肺癌篩檢有可能會出現偽陽性的結果，也可能會導致過度治療的決策。
                  </p>
                  <p>
                    <div className="float-right">
                      <MyPager
                        link={"/TraditionalChinese/benefits-and-harms-screening"}
                        label={"了解更多"}
                        arrowDirection={"right"}
                      ></MyPager>
                    </div>
                  </p>
                </div>
              )
            },
            {
              title:
                "除了吸菸之外，其他的肺癌風險因子",
              content: (
                <div>
                  <p>
                    至今，雖然吸菸仍然是導致肺癌最大的因素，但「氡」和職業暴露（例如石棉，砷，二氧化矽，鎘）也是肺癌的風險因子。
                  </p>
                  <p>
                    <div className="float-right">
                      <MyPager
                        link={"/TraditionalChinese/causes-of-lung-cancer"}
                        label={"了解更多"}
                        arrowDirection={"right"}
                      ></MyPager>
                    </div>
                  </p>
                </div>
              )
            }
          ]
        }
      ]
    },
    causePage: {
      banner_image_source: "/image/smoke.jpg",
      banner_title: "肺癌篩檢(檢查)",
      cause_info: {
        title: "肺癌及其成因",
        causes: [
          <p>
            肺癌始於肺氣道中遭受永久性損傷的細胞。這種損傷會導致細胞異常不受控制地生長，並可能擴散到身體的其他部位（轉移）。肺癌是全球癌症相關死亡的主要原因。
          </p>,
          <p>
            肺癌的成因有很多，其中最重要和最廣為人知的風險因素是<strong>吸菸</strong>。
          </p>
        ]
      },
      cause_chart: {
        title: "肺癌成因的比例預估*",
        src: "/image/attributable_risks_tiff2_English.png",
        text:
          "*Alberg AJ, Samet JM. Epidemiology of Lung Cancer. Chest. 2003; 123:21-49."
      },
      risk_info: {
        title: "其他已知的可能肺癌致病成因有：",
        risks: [
          "長期接觸氡氣（比如礦工等職業長期接觸的一種無色、無味的氣體，可積聚在一些家庭的地下室）；",
          "於工作時接觸石棉、砷和某些類型的鉻和二氧化矽；",
          "由醫生做出的慢性阻塞性肺病 (Chronic Obstructive Pulmonary Disease, COPD) 或肺氣腫和/或慢性支氣管炎的診斷；以及",
          "有家族肺癌病史。"
        ]
      }
    },
    comparePage: {
      image_info: {
        link: "/image/fruit.png",
        attribution: {
          link: "https://creativecommons.org/licenses/by-sa/2.0/",
          label: "MicroAssist/Flickr: CC BY-SA 2.0"
        }
      },
      title:
        "肺癌 CT 篩檢與其他癌症篩檢相比如何？",
      sub_title: "你知道嗎？",
      content:
        "與其他常見的癌症篩檢方法（如乳腺癌乳房 X 光篩檢）相比，肺癌 CT 篩檢在減少高危人群肺癌死亡方面的效果至少與乳房 X 光篩檢在預防乳腺癌死亡方面一樣有效。",
      comparison_table: {
        column_title_list: [
          <div>
            <center>
              Type of cancer screening<sup>1</sup>
            </center>
          </div>,
          <div>
            <center>Years of annual screens</center>
          </div>,
          <div>
            <center>
              Number of cancer deaths prevented<sup>2</sup>
            </center>
          </div>
        ],
        row_list: [
          {
            title: "Lung",
            cell_list: [3, 3.1]
          },
          {
            title: "Colorectal",
            cell_list: [5, 2.8]
          },
          {
            title: "Breast",
            cell_list: ["", ""]
          },
          {
            title: "Age 60-69",
            cell_list: [10, 2.6]
          },
          {
            title: "Age 50-59",
            cell_list: [10, 0.8]
          }
        ]
      },
      foot_note_list: [
        <div>
          <sup>1</sup>此處與肺癌、結腸直腸癌和乳腺癌篩檢相對應的程序分別是：低劑量 CT 掃描、帶有糞便潛血檢測的乙狀結腸鏡檢查和乳房 X 光攝影。
        </div>,
        <div>
          <sup>2</sup>這表明每 1000 人接受篩檢的死亡人數。
        </div>
      ]
    },
    disclaimerPage: {
      title: "免責聲明",
      content:
        "本決策幫助中提供的資訊僅用於一般健康資訊。 風險計算器只能根據您提供的回答來估計您的風險。 即使我們嘗試提供可用的最佳估計值，但對於個人而言，這些數字可能並不准確。 舉例說，可能存在未測量的特定風險因素。 因此，本網站上提供的資訊不能替代健康專業人士的醫療建議。 如果您擔心肺癌或患肺癌的風險，請向您的醫生或其他醫療保健提供者諮詢。 本網站包含指向第三方運營的其他網站的鏈接，我們無法保證任何第三方網站的陳述或其內容的準確性。"
    },
    howPage: {
      title: "肺癌篩檢(檢查)須知",
      banner_image_source: "/image/um-ct-shoot.jpg",
      banner_title: "肺癌篩檢(檢查)",
      sample_list: [
        {
          title: "問題一：什麼是肺癌篩檢？",
          content: (
            <div>
              <p>
              肺癌篩檢使用低輻射劑量電腦斷層掃描（LDCT），即低劑量的 CT 掃描，以發現肺結節，而這些結節其中一些可能是癌症。 參與篩檢的人，因為較容易及早發現癌症並作相對應的處理，可以降低死於肺癌的機會。
              </p>
              <p>
              通常，肺癌篩檢不是一次性的檢查，而是在醫生指引下進行的一個篩檢過程，其中包括檢驗結果的後續追蹤，以及在您仍符合資格的情況下每年進行持續性的篩檢。
              </p>
            </div>
          )
        },
        {
          title: "問題二：誰有資格進行篩檢？",
          content: (
            <div>
              <p>
              並不是每個人都適合進行肺癌篩檢。 根據美國預防服務工作隊 (The United States Preventive Services Task Force) 的說法，您必須符合以下條件才能進行篩檢：
              </p>
              <ul>
                <li>年齡介於 50 至 80 歲之間，</li>
                <li>在過去 15 年內有吸菸，並且</li>
                <li>吸菸量在 20 包年 (pack-years) 或以上。</li>
              </ul>
              <p>
                包年 (pack-year) 是用於描述您一生中抽了多少支香菸的一種單位，一包以 20 支香菸來計算。 如果您在過去 20 年中每天吸一包菸，或者在過去 10 年中每天吸兩包菸，則您有 20 個包年 (pack-years)。
                點選{" "}
                <Link
                  to={"/TraditionalChinese/pack-year-calculator"}
                  style={{ width: "100%", textDecoration: "none" }}
                  data-on="click"
                  data-event-category="HowPage"
                  data-event-action={"/TraditionalChinese/pack-year-calculator"}
                >
                  這個連結
                </Link>{" "}
                來計算您有多少包年。
              </p>
              <p>
              注意：持有聯邦老人醫療保險 (Medicare) 的高危險群，且其年齡介於 50 至 77 歲之間的人可以接受篩檢並由保險補助。
              </p>
            </div>
          )
        },
        {
          title: "問題三：篩檢是怎麼進行的？",
          content: (
            <div>
              <p>
                以下說明改編自{" "}
                <a
                  href="https://www.radiologyinfo.org/en/info.cfm?pg=screening-lung#part_three"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  美國放射學學會 (American College of Radiology)
                </a>
                ：
              </p>
              <p>
              在進行低劑量電腦斷層掃描(CT)篩檢的過程中，您將平躺在檢驗台上。因為需要保持正確的姿勢，在檢查過程中會用枕頭和綁帶幫助您保持靜止，並要求您將手臂舉過頭頂。 接下來，檢驗台會移動以便將您放在掃描儀中正確的起始位置。 掃描過程中，會請您屏住呼吸 5 到 10 秒鐘，檢驗台會持續移動讓您通過掃描儀。
              </p>
            </div>
          )
        },
        {
          title: "問題四：進行第一次篩檢後有什麼後續的步驟嗎?",
          content: (
            <div>
              <p>
                如果在電腦斷層掃描 (CT) 掃描中發現肺結節，您的醫生可能會建議進行後續 CT 掃描。後續的掃描通常會在 3 - 6 個月後進行來檢查結節是否有增長。肺結節是圓形或橢圓形的斑點。在極少數的情況下，如果結節確實生長或可能引起健康疑慮，您的醫生可能會建議使用 {" "}
                <a
                  href="https://medlineplus.gov/ency/article/007342.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  正電子 (PET) 掃描
                </a>{" "}
                或活體組織切片（取出一小塊結節）進行進一步檢查。
              </p>
              <p>
              但是，由於發現的大多數肺結節不是癌症，您可能只需要像其他年度健康檢查一樣在一年後返回繼續篩檢過程。 請記住，肺癌篩檢不是一個單一的測試：它是一個必須在您的醫生的指導下正確完成的過程。
              </p>
            </div>
          )
        },
        {
          title:
            "問題五：用於肺癌篩檢的電腦斷層 (CT) 掃描會讓我暴露在多少輻射之下？",
          content: (
            <div>
              <p>
                用於肺癌篩檢的 CT 掃描使用的輻射比傳統胸部 CT 掃描少 90%。近似的有效輻射劑量為 1.5 毫西弗 (mSv)，相當於大約 6 個月的「正常」背景輻射，風險非常低。事實上，目前並沒有發現在背景輻射和癌症風險之間有相關性。 從一次檢查中患上致命肺癌的額外風險低到難以衡量，估計的機率在 100,000 分之一到 10,000 分之一之間。想了解更多有關輻射暴露的資訊，您可以查閱{" "}
                <a
                  href="https://www.radiologyinfo.org/en/info.cfm?pg=safety-xray"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  此處
                </a>
                {" "}或是{" "}
                <a
                  href="http://www.xrayrisk.com/calculator/calculator-normal-studies.php?id=68"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  此處
                </a>
                。
              </p>
            </div>
          )
        },
        {
          title: "問題六：我可以在哪裡接受篩檢？",
          content: (
            <div>
              <p>
              您應當在經認可的機構接受篩檢。 你可以用{" "}
                <a
                  href="
                  https://www.acr.org/Clinical-Resources/Lung-Cancer-Screening-Resources/LCS-Locator-Tool"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  這個網站
                </a>
                {" "}找到一個離你近的篩檢機構。
              </p>
            </div>
          )
        }
      ]
    },
    riskPage: {
      banner_image_source: "/image/gsh-sa-balloons.jpg",
      banner_title: "降低您罹患肺癌的風險",
      title:
        "想要降低罹患肺癌的風險，最好的方法就是戒菸。",

      description: (
        <p>
          若您有抽菸的話，LDCT斷層掃描雖然可以降低您死於肺癌的機率, 但<strong>更為</strong>有效的方法就是戒菸。這雖然困難，但仍是可以做到的。想要成功戒菸，您必須克服對於尼古丁的渴癮以及根深蒂固的抽菸習慣。許多人以「壓力」為由，而不嘗試戒菸。但事實上，壓力本就是日常生活的一部分，若只是等待壓力自動「消失」，而不做有益健康的事情，那最終只會導向失敗的結果。我們必須學習各種因應壓力的方法，先從和醫生討論您的壓力來源開始。外面有許多資源可以幫助您戒菸，有些資源可以免費或降價為您提供美國食品藥物管理局（FDA）批准的戒菸協助。以下提供一些資源給您參考：
        </p>
      ),
      state_resource_list: [
        { state: "選擇一個州", links: [] },
        ,
        {
          state: "阿拉巴馬州",
          links: [
            {
              label:
                "阿拉巴馬州公共衛生部菸草防治",
              link: "http://www.adph.org/tobacco/Default.asp?id=779"
            }
          ]
        },

        {
          state: "阿拉斯加州",
          links: [
            {
              label: "無菸阿拉斯加",
              link:
                "http://dhss.alaska.gov/dph/Chronic/Pages/Tobacco/TobaccoFreeAlaska/default.aspx"
            }
          ]
        },

        {
          state: "亞利桑那州",
          links: [
            {
              label: "ASHLine-亞利桑那州的吸菸者幫助專線",
              link: "https://ashline.org/"
            },
            {
              label: "無菸亞利桑那",
              link: "http://azdhs.gov/tobaccofreeaz/"
            }

            
          ]
        },

        {
          state: "阿肯色州",
          links: [
            {
              label: "阿肯色州戒菸資源",
              link:
                "http://www.healthy.arkansas.gov/programs-services/topics/tobacco-cessation-resources"
            },
            {
              label: "消滅吸菸",
              link: "http://www.stampoutsmoking.com/get-help-to-quit/"
            }
          ]
        },

        {
          state: "加利福尼亞州",
          links: [
            {
              label: "加州菸草控制計劃",
              link:
                "https://www.cdph.ca.gov/Programs/CCDPHP/DCDIC/CTCB/Pages/CessationServicesAndResources.aspx"
            },
            {
              label: "加州吸菸者幫助專線",
              link: "https://www.nobutts.org/"
            }
          ]
        },

        {
          state: "科羅拉多州",
          links: [
            {
              label: "科羅拉多州公共衛生與環境部",
              link: "https://www.colorado.gov/pacific/cdphe/quit-tobacco"
            },
            {
              label: "無菸科羅拉多",
              link: "http://www.tobaccofreeco.org/"
            }
          ]
        },

        {
          state: "康涅狄格州",
          links: [
            {
              label: "康涅狄格州公共衛生部",
              link:
                "http://www.ct.gov/dph/cwp/view.asp?a=3137&q=388064&dphNav=|&dphNav_GID=1841"
            }
          ]
        },

        {
          state: "特拉華州",
          links: [
            {
              label: "特拉華州菸草防治計劃",
              link: "http://dhss.delaware.gov/dhss/dph/dpc/tobacco.html"
            }
          ]
        },

        {
          state: "佛羅里達州",
          links: [
            {
              label: "無菸佛羅里達",
              link:
                "http://www.floridahealth.gov/programs-and-services/prevention/tobacco-free-florida/index.html"
            }
          ]
        },

        {
          state: "喬治亞州",
          links: [
            {
              label: "喬治亞州公共衛生部-準備戒菸",
              link: "https://dph.georgia.gov/ready-quit"
            }
          ]
        },

        {
          state: "夏威夷州",
          links: [
            {
              label: "夏威夷州衛生部菸草控制",
              link: "http://health.hawaii.gov/tobacco/home/cessation/"
            },
            {
              label: "夏威夷州菸草戒菸專線",
              link: "http://hawaiiquitline.org"
            }
          ]
        },

        {
          state: "愛達荷州",
          links: [
            {
              label: "愛達荷州菸草防治計劃",
              link:
                "http://www.healthandwelfare.idaho.gov/Health/TobaccoPreventionandControl/tabid/324/Default.aspx"
            },
            { label: "過濾計畫", link: "http://projectfilter.org/" }
          ]
        },

        {
          state: "伊利諾州",
          links: [
            {
              label: "伊利諾州菸草戒菸專線",
              link: "http://quityes.org/home.html"
            }
          ]
        },

        {
          state: "印第安納州",
          links: [
            {
              label: "印第安納州衛生署",
              link: "http://www.in.gov/isdh/tpc/2353.htm"
            },
            {
              label: "即刻戒菸印第安納州",
              link: "http://www.quitnowindiana.com/"
            }
          ]
        },

        {
          state: "愛荷華州",
          links: [
            {
              label: "愛荷華州戒菸專線",
              link: "https://iowa.quitlogix.org/"
            }
          ]
        },

        {
          state: "堪薩斯州",
          links: [
            {
              label: "堪薩斯州衛生與環境部",
              link: "http://www.kdheks.gov/tobacco/cessation.html"
            },
            { label: "堪薩斯州戒菸!", link: "http://www.ksquit.org/" }
          ]
        },

        {
          state: "肯塔基州",
          links: [
            {
              label: "肯塔基州菸草防治和戒菸計劃",
              link: "http://chfs.ky.gov/dph/mch/hp/tobacco.htm"
            }
          ]
        },

        {
          state: "路易斯安那州",
          links: [
            {
              label: "一起戒菸，路易斯安那州",
              link: "http://www.quitwithusla.org/"
            }
          ]
        },

        {
          state: "緬因州",
          links: [
            {
              label: "緬因州無菸合夥",
              link: "http://www.tobaccofreemaine.org/"
            }
          ]
        },

        {
          state: "馬里蘭州",
          links: [
          { 
            label: "吸菸到此為止-馬里蘭州的戒菸資源", 
            link: "https://smokingstopshere.com/" 
          },
          { 
            label: "馬里蘭州戒菸", 
            link: "http://mdquit.org/" 
          }
            ]
        },

        {
          state: "麻薩諸塞州",
          links: [
            {
              label: "讓吸菸成為過去",
              link: "http://makesmokinghistory.org/"
            }
          ]
        },

        {
          state: "密西根州",
          links: [
            {
              label: "密西根州社區衛生部",
              link:
                "http://www.michigan.gov/mdhhs/0,5885,7-339-71550_2955_2973_53244---,00.html"
            },
            {
              label: "密西根大學菸草處遇計劃",
              link:
                "https://hr.umich.edu/benefits-wellness/health-well-being/mhealthy/faculty-staff-well-being/alcohol-tobacco-programs/tobacco-consultation-service"
            }
          ]
        },

        {
          state: "明尼蘇達州",
          links: [
            {
              label: "明尼蘇達州衛生部",
              link: "http://www.health.state.mn.us/divs/hpcd/tpc/quit.html"
            },
            {
              label: "戒菸計劃服務。沒有批判只有協助。",
              link: "https://www.quitplan.com/"
            }
          ]
        },

        {
          state: "密西西比州",
          links: [
            {
              label: "密西西比州菸草控制辦公室",
              link:
                "http://www.msdh.state.ms.us/msdhsite/_static/43,0,94.html#community"
            },
            {
              label: "密西西比州戒菸專線",
              link: "http://www.quitlinems.com/"
            }
          ]
        },

        {
          state: "密蘇里州",
          links: [
            {
              label: "密蘇里州衛生與老年服務部",
              link:
                "http://health.mo.gov/living/wellness/tobacco/smokingandtobacco/tobaccocontrol.php"
            }
          ]
        },
        {
          state: "蒙大拿州",
          links: [
            {
              label: "蒙大拿州菸草使用防治計劃",
              link: "http://dphhs.mt.gov/publichealth/mtupp"
            },
            {
              label: "蒙大拿州戒菸專線",
              link: "https://montana.quitlogix.org/"
            }
          ]
        },
        {
          state: "內布拉斯加州",
          links: [
            {
              label: "無菸內布拉斯加州",
              link:
                "http://dhhs.ne.gov/publichealth/Pages/smokefree_sf_ql.aspx"
            }
          ]
        },

        {
          state: "內華達州",
          links: [
            {
              label: "內華達州菸草防治計劃",
              link:
                "http://dpbh.nv.gov/Programs/TPC/Tobacco_Prevention_and_Control_-_Home/"
            },
            {
              label: "內華達州菸草預防聯盟",
              link:
                "http://www.tobaccofreenv.org/tobacco-information/quitting-smoking"
            }
          ]
        },

        {
          state: "新罕布夏州",
          links: [
            {
              label: "新罕布夏州菸草防治計劃",
              link: "http://www.dhhs.state.nh.us/dphs/tobacco/index.htm"
            },
            {
              label: "新罕布夏州菸草專線",
              link: "http://trytostopnh.org/"
            }
          ]
        },
        {
          state: "紐澤西州",
          links: [
            {
              label: "紐澤西州菸草控制計劃",
              link: "http://www.nj.gov/health/fhs/tobacco"
            },
            {
              label: "紐澤西州戒菸專線",
              link: "http://www.njquitline.org/treatment.html"
            }
          ]
        },

        {
          state: "新墨西哥州",
          links: [
            {
              label: "TUPAC：菸草使用的防治",
              link: "http://nmtupac.com/#home"
            }
          ]
        },

        {
          state: "紐約州",
          links: [
            {
              label: "紐約州吸菸者戒菸專線",
              link: "https://nysmokefree.com/"
            }
          ]
        },

        {
          state: "北卡羅來納州",
          links: [
            {
              label: "北卡戒菸專線",
              link: "http://www.quitlinenc.com/"
            }
          ]
        },

        {
          state: "北達科他州",
          links: [
            {
              label: "北達科他州菸草防治計劃",
              link: "http://www.ndhealth.gov/tobacco/"
            },
            {
              label: "北達科他州戒菸計劃",
              link: "http://www.ndhealth.gov/tobacco/cessation.htm"
            }
          ]
        },

        {
          state: "俄亥俄州",
          links: [
            {
              label: "俄亥俄州衛生部菸草計劃",
              link:
                "http://www.odh.ohio.gov/en/odhprograms/eh/quitnow/Tobacco/Cessation/Cessation"
            }
          ]
        },

        {
          state: "奧克拉荷馬州",
          links: [
            {
              label: "奧克拉荷馬州菸草使用預防和減少計劃",
              link:
                "http://www.ok.gov/health/Wellness/Tobacco_Prevention/Programs/index.html"
            }
          ]
        },

        {
          state: "俄勒岡州",
          links: [
            {
              label: "俄勒岡州菸草預防和教育計劃",
              link:
                "http://www.oregon.gov/oha/PH/PreventionWellness/TobaccoPrevention/GetHelpQuitting/Pages/oregonquitline.aspx"
            },
            {
              label: "無菸俄勒岡州",
              link: "https://www.quitnow.net/oregon/"
            }
          ]
        },

        {
          state: "賓夕法尼亞州",
          links: [
            {
              label: "賓夕法尼亞州菸草防治",
              link:
                "http://www.health.pa.gov/My%20Health/Healthy%20Living/Smoke%20Free%20Tobacco/Pages/default.aspx#.WbqmgfmGPcs"
            },
            {
              label: "賓州免費戒菸專線",
              link:
                "http://www.health.pa.gov/My%20Health/Healthy%20Living/Smoke%20Free%20Tobacco/Pages/PA-Free-Quitline.aspx#.WbrmPdOGORt"
            }
          ]
        },

        {
          state: "羅德島州",
          links: [
            {
              label: "羅得島州菸草控制計劃",
              link: "http://www.health.ri.gov/programs/tobaccocontrol/"
            },
            { label: "即刻戒除 羅得島州", link: "http://www.quitnowri.com/" }
          ]
        },
        {
          state: "南卡羅來納州",
          links: [
            {
              label: "南卡羅來納州戒菸",
              link: "http://www.scdhec.gov/Health/TobaccoCessation/"
            }
          ]
        },
        {
          state: "南達科他州",
          links: [
            {
              label: "南達科他州菸草防治計畫",
              link: "http://doh.sd.gov/prevention/tobacco/"
            },
            {
              label: "南達科他州戒菸專線",
              link: "https://www.sdquitline.com/"
            }
          ]
        },
        {
          state: "田納西州",
          links: [
            {
              label: "田納西州戒菸專線",
              link: "http://health.state.tn.us/tobaccoquitline.htm"
            }
          ]
        },
        {
          state: "德克薩斯州",
          links: [
            {
              label: "德克薩斯州衛生服務部",
              link: "https://www.dshs.state.tx.us/tobacco/links.shtm"
            },
            { label: "（有誤）Tobacco is foul", link: "http://www.ducktexas.org/" }
          ]
        },
        {
          state: "猶他州",
          links: [
            {
              label: "猶他州菸草防治計畫",
              link: "http://www.tobaccofreeutah.org/"
            },
            {
              label: "戒菸之路",
              link: "http://waytoquit.org/"
            }
          ]
        },

        {
          state: "佛蒙特州",
          links: [
            {
              label: "佛蒙特州衛生部",
              link:
                "http://www.healthvermont.gov/prevent/tobacco/quit_smoking.aspx"
            },
            {
              label: "802 戒菸：佛蒙特州戒菸資源",
              link: "http://802quits.org/"
            }
          ]
        },
        {
          state: "維吉尼亞州",
          links: [
            {
              label: "維吉尼亞菸草使用控制計畫",
              link: "http://www.vdh.virginia.gov/ofhs/prevention/tucp/"
            }
          ]
        },

        {
          state: "華盛頓州",
          links: [
            {
              label:
                "華盛頓州衛生菸草資源部",
              link:
                "http://www.doh.wa.gov/ForPublicHealthandHealthcareProviders/PublicHealthSystemResourcesandServices/LocalHealthResourcesandTools/Tobacco"
            }
          ]
        },

        {
          state: "西維吉尼亞州",
          links: [
            {
              label: "西維吉尼亞菸草預防資源",
              link:
                "http://www.dhhr.wv.gov/wvdtp/cessation/Pages/default.aspx"
            }
          ]
        },

        {
          state: "威斯康辛州",
          links: [
            {
              label: "威斯康辛州菸草防治計畫",
              link: "https://www.dhs.wisconsin.gov/tobacco/index.htm"
            },
            {
              label: "威斯康辛州戒菸專線",
              link: "http://www.ctri.wisc.edu/quitline.html"
            }
          ]
        },

        {
          state: "懷俄明州",
          links: [
            {
              label:
                "懷俄明州衛生部菸草預防計劃",
              link:
                "https://health.wyo.gov/publichealth/prevention/tobacco-prevention/wqtp/"
            }
          ]
        }
      ],
      resources_quit: [
        {
          link: "",
          label: <a href="tel:8008388917">Call 1-800-838-8917</a>
        },
        {
          link: "https://www.asiansmokersquitline.org/quitnow/tchinese/",
          label: "華語戒菸專線"
        },
        {
          link: "http://betobaccofree.hhs.gov/quit-now/index.html",
          label: "戒菸 BeTobaccoFree.gov (英文版)"
        },
        {
          link:
            "https://www.cdc.gov/tobacco/features/new-year/chinese/index.html",
          label: "美國疾病管制與預防中心"
        }
      ],
      resources_instructions:
        "請從下拉選單中選擇一個州，以獲得可用的資源。",
      radon_instructions:
        "「氡」是肺癌的另一個可預防的風險因子。 了解如何降低風險：",
      resources_radon: [
        {
          link:
            "https://www.epa.gov/sites/production/files/2016-12/documents/2016_a_citizens_guide_to_radon.pdf",
          label:
            "關於「氡」的市民指南-環境保護局編制 (英文版)"
        },
        {
          link:
            "http://nehrc.nhri.org.tw/toxic/toxfaq_detail_mobile.php?id=152",
          label: "台灣環境毒物研究中心 (原文翻譯自美國疾病管制與預防中心)"
        }
      ]
    },
    packPage: {
      title: "抽菸量(包年)計算器",
      instruction: {
        normal:
          "「包年」這個量詞用於描述您一生中抽了多少支香菸，一包等於 20 支香菸。如果您在過去 20 年中每天吸一包，或在過去 10 年中每天吸兩包，那麼您有 20 包年。換句話說，包年是衡量吸菸暴露的一種方法，它可以看出您吸菸的時間和吸菸量。目前，需要滿足多項標準中「有 20 包年（或以上）」才會建議進行肺癌篩檢(檢查)。"
      },

      sub_title:
        "請回答以下兩個問題，來算出您的「包年」。",
      submit_button_label: "計算",
      calculation_result_template:
        "您的吸菸暴露量為 x_number 包年。", // x_number will be replaced with calculation result, don't change it.
      pack_questions: {
        formYears: {
          label: "1. 請問您吸菸幾年了？",
          placeholder: "多少年？"
        },
        formAmount: {
          label:
            "2. 平均而言，請問您每天抽幾支菸？一包菸內有 20 支香菸。",
          placeholder: "每天抽幾支菸？"
        }
      }
    },
    calculatePage: {
      banner_image_source: "/image/areachart2.png",
      banner_title: "您的肺癌風險",
      title:
        "如果您吸菸，降低患肺癌風險的最佳方法是戒菸。",

      instruction: {
        normal: (
          <div>
            <p>
            填寫以下資訊來了解您是否屬於{" "}
              <a
                target="_blank"
                href="https://www.uspreventiveservicestaskforce.org/Page/Document/RecommendationStatementFinal/lung-cancer-screening"
                rel="noopener noreferrer"
              >
                美國預防服務工作組
              </a>
              {" "}建議篩檢(檢查)的對象。這個計算機也會表明您從篩檢中受益的程度。 這將幫助您更好地確定篩檢的潛在益處是否大於危害。
            </p>
          </div>
        ),
        strong_text_list: ["* 表示必要的欄位"]
      },
      submit_button_label: "提交",
      default_select_label: " -- 選擇 -- ",
      error_input_guidance:
        "您好，您填寫的一項或多項內容可能有錯誤。 請向下捲動並檢查。",
      tall_question: (
        <div>
          9. 您身高有多高？ &nbsp;
          <a
            target="_blank"
            //href="https://www.google.com/search?q=convert+centimeters+to+feet+and+inches&ie=utf-8&oe=utf-8"
            href="https://share.streamlit.io/should-i-screen/unit-converter/main?lan=zh-TW"
            rel="noopener noreferrer"
          >
            請幫我把公分換算成英尺和英寸。
          </a>
        </div>
      ),
      risk_questions: {
        formOld: {
          label: "1. 您今年幾歲？*",
          placeholder: "",
          help_text:
            "問題 1 為必填的欄位，此計算機的有效年齡範圍為 18 至 100 歲。"
        },
        formCurrentStatus: {
          label: "2. 您目前的吸菸狀況如何？*",
          placeholder: "",
          options: [
            { label: "吸菸者", value: 1 },
            { label: "以前吸菸，現已戒菸", value: 0 },
            { label: "從不吸菸", value: 2 }
          ],
          help_text: "問題 2 為必填的欄位。"
        },
        formAgeLastSmoke: {
          label: "2.1. 您最後一次戒菸是在幾歲？*",
          placeholder: "",
          options: [],
          help_text:
            "你已經表明你以前是吸菸者。 請確保您在問題 1 中填寫的數字小於您當下的年齡。"
        },
        formYearsSmoking: {
          label: "3. 你總共抽了多少年的菸？*",
          placeholder: "",
          options: [],
          help_text:
            "問題 3 是必填的欄位。 請確保此值小於您當下的年齡，並且是一個數字。"
        },
        formCigarettesPerDay: {
          label:
            "4. 平均而言，您每天抽/吸多少支香菸？* 一包有 20 支香菸。",
          placeholder: "",
          options: [],
          help_text:
            "問題 4 是必填的欄位。 請確保您填寫了 0 到 200 之間的數字。"
        },
        formGender: {
          label: "5. 你的性別是？",
          placeholder: "",
          options: [
            { label: "男性", value: "male" },
            { label: "女性", value: "female" }
          ]
        },
        formInsurance: {
          label: <div>6. 您是否在聯邦老人醫療保險（Medicare）承保範圍內？🏥</div>,
          placeholder: "",
          options: [
            { label: "是", value: "yes" },
            { label: "否", value: "no" },
            { label: "不知道/不適用", value: "unknown" }
          ]
        },
        formHighestGradeCompleted: {
          label:
            "7. 您完成的最高年級或學年(最高學歷)是什麼？",
          placeholder: "",
          options: [
            { label: "高中以下", value: 0 },
            { label: "高中畢業", value: 1 },
            { label: "高中畢業後的一些訓練", value: 2 },
            { label: "一些大學經歷", value: 3 },
            { label: "大學畢業", value: 4 },
            { label: "研究生或專業學位", value: 5 }
          ]
        },
        formRaceEthnicity: {
          label: "8. 您如何描述您的種族/民族？",
          placeholder: "",
          options: [
            { label: "白人", value: 0 },
            { label: "黑人", value: 1 },
            { label: "西班牙裔", value: 2 },
            { label: "亞裔", value: 3 },
            { label: "美洲印第安人或阿拉斯加原住民", value: 4 },
            { label: "夏威夷原住民或太平洋島民",value: 5 }
          ]
        },
        formTallFeet: {
          label: "",
          placeholder: "",
          right_side_label: "英尺",
          options: [
            { label: "3", value: 3 },
            { label: "4", value: 4 },
            { label: "5", value: 5 },
            { label: "6", value: 6 },
            { label: "7", value: 7 },
            { label: "8", value: 8 },
            { label: "9", value: 9 }
          ]
        },
        formTallInch: {
          label: "",
          placeholder: "",
          right_side_label: "英寸",
          options: [
            { label: "0", value: 0 },
            { label: "1", value: 1 },
            { label: "2", value: 2 },
            { label: "3", value: 3 },
            { label: "4", value: 4 },
            { label: "5", value: 5 },
            { label: "6", value: 6 },
            { label: "7", value: 7 },
            { label: "8", value: 8 },
            { label: "9", value: 9 },
            { label: "10", value: 10 },
            { label: "11", value: 11 }
          ]
        },
        formWeight: {
          label: (
            <div>
              10. 您有多重？（磅）&nbsp;
              <a
                target="_blank"
                //href="https://www.google.com/search?q=convert+kilograms+to+pounds&ie=utf-8&oe=utf-8"
                href="https://share.streamlit.io/should-i-screen/unit-converter/main?lan=zh-TW"
                rel="noopener noreferrer"
              >
                請幫我把公斤換算成磅。
              </a>
            </div>
          ),
          placeholder: "",
          options: [],
          help_text:
            "請確保您在問題 9 中輸入了 45 到 1000 之間的數字。這不是必填的欄位。如果您選擇不填，計算器會帶入一個預設值。"
        },
        formDoctorToldCancer: {
          label:
            "11. 您是否曾被醫生告知您患有癌症？",
          placeholder: "",
          options: [
            { label: "是", value: 1 },
            { label: "否", value: 0 }
          ]
        },
        formFamilyHistory: {
          label: "12. 請問您是否有肺癌家族病史？",
          placeholder: "",
          options: [
            { label: "是", value: 1 },
            { label: "否", value: 0 }
          ]
        },
        formDoctorToldCOPD: {
          label:
            "13. 您的醫生是否曾告知您患有慢性肺病，也稱為慢性支氣管炎或肺氣腫（COPD）？ ",
          options: [
            { label: "是", value: 1 },
            { label: "否", value: 0 }
          ]
        }
      }
    },
    feedbackPage: {
      title: "感謝您的回饋！",
      submitted_message: "感謝您！",
      default_select_label: " -- 選擇 -- ",
      submit_button_label: "提交",
      feedback_questions: {
        formGender: {
          label: "我是",
          placeholder: "",
          options: [
            { label: "女性", value: 0 },
            { label: "男性", value: 1 }
          ]
        },
        formSmokeStatus: {
          label: "我...",
          placeholder: "",
          options: [
            { label: "是一個吸菸者。", value: 1 },
            { label: "以前抽菸。", value: 0 },
            { label: "從來沒有抽過菸。.", value: 2 }
          ]
        },
        formAge: {
          label: "您幾歲？",
          placeholder: "50",
          options: []
        },
        formCalculatorResult: {
          label: "根據您在風險計算器中輸入的內容, 您:",
          placeholder: "",
          options: [
            {
              label: "有資格接受篩檢。",
              value: "Eligible to be screened"
            },
            {
              label: "沒有資格接受篩檢。",
              value: "Not eligible to be screened"
            },
            {
              label: "風險太低，無法準確計算。",
              value: "Risk was too low to be calculated accurately"
            }
          ]
        },
        formInformationReceiver: {
          label: "我正在為＿＿尋找資訊。",
          placeholder: "",
          options: [
            { label: "自己", value: "Myself" },
            { label: "我的伴侶", value: "My partner" },
            { label: "我的家人(們)", value: "My family member(s)" },
            { label: "我的朋友(們)", value: "My friend(s)" },
            { label: "我的病人(們)", value: "My patient(s)" }
          ]
        },
        formFindAllInformation: {
          label:
            "我找到了所有我正在尋找的有關肺癌篩檢的資訊。",
          placeholder: "",
          options: [
            { label: "強烈反對", value: -2 },
            { label: "反對", value: -1 },
            { label: "不同意也不反對", value: 0 },
            { label: "同意", value: 1 },
            { label: "強烈同意", value: 2 }
          ]
        },
        formHelpConversationDoctor: {
          label:
            "我找到的資訊對跟我的醫生討論肺癌篩檢有所幫助。",
          placeholder: "",
          options: [
            { label: "強烈反對", value: -2 },
            { label: "反對", value: -1 },
            { label: "不同意也不反對", value: 0 },
            { label: "同意", value: 1 },
            { label: "強烈同意", value: 2 }
          ]
        },
        formHelpDecision: {
          label:
            "這個網站對我做關於肺癌篩檢的決定有所幫助。",
          placeholder: "",
          options: [
            { label: "強烈反對", value: -2 },
            { label: "反對", value: -1 },
            { label: "不同意也不反對", value: 0 },
            { label: "同意", value: 1 },
            { label: "強烈同意", value: 2 }
          ]
        },
        formInterestReason: {
          label: "我對肺癌篩檢感興趣是因為...",
          placeholder: "例如，我有癌症家族史。"
        },
        formComment: {
          label:
            "請隨時提交您認為有助於我們改進網站的任何其他意見：",
          placeholder: ""
        }
      }
    },
    resultPage: {
      print_button_label: "列印",
      title: "肺癌篩檢(檢查):",
      sub_title: "我應該接受篩檢嗎?",
      risk_spectrum: {
        risk_statement: "您的肺癌風險：",
        low_risk_label: <span>&#60; 1%: 低風險</span>,
        intermediate_risk_label: <span>1-2%: 中等風險</span>,
        high_risk_label: <span>&#62; 2%: 高風險</span>
      },
      risk_confirmation:{
        label: "根據圖表，我患肺癌的風險是：",
        placeholder: "",
        options: [
          { label: "高", value: "High" },
          { label: "中等", value: "Intermediate" },
          { label: "低", value: "Low" },
          { label: "不知道", value: "Don't know" }
        ]
      },
      comparison_chart_info_list:[
        {
          chartTitle:"未篩檢",
          legendTitle: {
            "1000": "在1000個像你這樣的人中",
            "100": "在100個像你這樣的人中"
          },
          legendMap:{
            "die": "死於肺癌",
            "not die": "沒有死於肺癌"
          }
        },
        {
          chartTitle:"篩檢",
          legendTitle: {
            "1000": "在1000個像你這樣的人中",
            "100": "在100個像你這樣的人中"
          },
          legendMap:{
            "die": "死於肺癌",
            "fewer die": {
              "full": "因篩檢而沒有死於肺癌",
              "list": ["因篩檢而沒有",  "死於肺癌"]
            },
            "not die": "沒有死於肺癌"
          }
        },
      ],
      benefit_confirmation:{
        //label: "After seeing the chart, I consider my benefits of getting screened being:",
        label: "快速確認：根據上述資訊，我認為篩檢的好處，相對於我在未來6年患肺癌的風險，是",
        placeholder: "",
        options: [
          { label: "足夠高", value: "High enough" },
          //{ label: "Intermediate", value: "Intermediate" },
          { label: "太低", value: "Too low" },
          { label: "不知道", value: "Don't know" }
        ]
      },
      risk_section: {
        title: "風險",
        statement_list: [
          "在 1000 名接受篩檢的人中，約會有 120 人發現了並非癌症的肺結節。",
          "在 1000 人中約有 13 人由於非癌症的肺結節接受侵入性處置，如活檢或手術。",
          "1000人中只有不到 1 人因侵入性手術而出現重大併發症。",
          "在篩檢發現的肺癌中，不到十分一的肺癌會傷害你。這可能導致不必要的治療和併發症。"
        ]
      },
      benefit_section: {
        title: "好處",
        statement_end:
          "與未接受篩檢的人相比， 較少像你這樣有接受篩檢的人會死於肺癌。"
      },
      instructions: {
        beforeSubmission: [
          "現在，您應該了解什麼是篩檢，它可以做什麼，不能做什麼。如果您能讓您的醫生知道您對篩檢的優缺點的理解跟想法，會很有幫助。請檢查下面的哪個句子最能描述您對以下問題的回答。提醒您，這個問題並沒有對或錯的答案！",
          "在您看來，您從篩檢中獲得的個人利益是否讓您覺得接受篩檢正確的選擇？在下面選擇一個選項。"

        ],
        afterSubmission: [
          "記得跟您的醫生談談您對肺癌篩檢的看法！"
        ]
      },
      default_select_label: " -- 選擇 -- ",
      submit_button_label: "提交",
      click_for_pdf_msg:
        "點選影像查看適合列印的版本。",
      getEligibilityStatement: function(sex, risk, eligibility, insurance, old, packYears, neverSmoked=false) {
        // sex: male, female
        // risk: 0.0 ~
        // eligibility: yes, no
        // insurance: yes, no, unknown
        // Old = -1;
        // yearsSmoking = -1;
        // cigarettesPerDay = -1;

        var result = "默認資格";

        if(neverSmoked){
          result = <div>肺癌 CT 篩檢不建議那些從未吸菸的人進行。</div>;
          return result;
        }

        switch (eligibility) {
          case "yes":
            result = "";

            if (insurance == "yes") {
              // M
              result = (
                <div>
                  根據美國預防服務工作隊的標準，您的年齡和吸菸史<strong>符合</strong>進行篩檢的資格。
                </div>
              );
            } else {
              if( old > 77){
                result = (
                  <div>
                    根據美國預防服務工作隊的標準，您的年齡和吸菸史<strong>符合</strong>進行篩檢的資格。然而，聯邦老人醫療保險只涵蓋77歲以下的肺癌篩檢。請參考本網站的
                    <Link
                      to={"/TraditionalChinese/coverage"}
                      style={{ width: "100%" }}
                      data-on="click"
                      data-event-category="CoveragePage"
                      data-event-action={"/TraditionalChinese/coverage"}
                    >
                      保險給付說明&nbsp;
                    </Link>
                    或聯繫您的保險公司，看看是否有其他選項，可能支付肺癌篩檢的費用。
                  </div>
                );
              }
              else{
                result = (
                  <div>
                    根據美國預防服務工作隊的標準，您的年齡和吸菸史<strong>符合</strong>進行篩檢的資格。
                  </div>
                );
              }
              
            }
            break;
          case "no":
            result = (<p>
              根據美國預防服務工作隊的標準，您的年齡和吸菸史<strong>不符合</strong>進行篩檢的資格。
            </p>
            );
            break;
          default:
            result = "";
            break;
        }

        return result;
      },
      getChanceStatement(sex, risk, eligibility, insurance, old, packYears) {

        // sex: male, female
        // risk: 0.0 ~
        // eligibility: yes, no
        // medicare: yes, no, unknown
        var result = "Default Chance";

        console.log(`getChanceStatement(), eligibility: ${eligibility}  risk: ${risk}` );


        let  tailorMessage = "";

        let oldInRange = old >= 50 && old <=54;
        let packYearsInRange = packYears >= 20 && packYears <=29;

        if(oldInRange && packYearsInRange){
          tailorMessage = `由於您的年齡在50-54歲之間，吸菸史為20-29包年，根據美國預防服務工作隊的最新標準，您符合肺癌篩檢的資格。您有可能不在保險的給付範圍，請跟您的醫療供給者詢問確切的資訊。`;
        }
        else if(!oldInRange && packYearsInRange){
          tailorMessage = `由於您的吸菸史為20-29包年，根據美國預防服務工作隊的最新標準，您符合肺癌篩檢的資格。您有可能不在保險的給付範圍，請跟您的醫療供給者詢問確切的資訊。`;
        }
        else if(oldInRange && !packYearsInRange){
          tailorMessage = `由於您的年齡在50-54歲之間，根據美國預防服務工作隊的最新標準，您符合肺癌篩檢的資格。您有可能不在保險的給付範圍，請跟您的醫療供給者詢問確切的資訊。`;
        }

        

        if (risk == 0 ) {
          // smoker or not
          result = (
            <div>
              你在未來6年罹患肺癌的機率太低，無法準確估計。如果您擔心患肺癌的風險，請諮詢您的醫生，因為他們會最瞭解您的情況。
            </div>
          );
        }
        else if (eligibility == "no") {
          if(risk >= 1.5){
            result = (<Fragment>
              <p>
                *然而，你在未來 6 年罹患肺癌的機率是 {risk}%，這超過了我們設定的門檻，表示您進行CT篩檢是利大於弊的。你應該考慮和你的醫生談談肺癌篩檢是否適合你。
              </p>
              </Fragment>
            );
          }
          else{
            result = (
              <div>
                你在未來 6 年罹患肺癌的機率是 {risk}%。請與您的醫生討論篩檢或不篩檢的選項，因為他們最瞭解您的情況。
              </div>
            );
          }
          
        } else if (eligibility == "yes") {
          if(risk < 1.5){
            result = (<Fragment>
              <p>
                {tailorMessage}
              </p>
              <p>
              *然而，你在未來 6 年罹患肺癌的機率是 {risk}%，這比我們設定的門檻低，表示篩檢的好處不夠充足讓您考慮 CT 篩檢這個選擇。你應該考慮和你的醫生談談肺癌篩檢是否仍然適合你。
              </p>

              </Fragment>
            );
          }
          else{
            result = (
              <div>
                <p>
                你在未來 6 年罹患肺癌的機率是 {risk}%。你應該考慮和你的醫生談談肺癌篩檢是否適合你。
                </p>          
                <p> 
                  {tailorMessage}
              </p>
              </div>
            );
          }
        }
        return result;
      },
      getExplanationStatement(sex, risk, eligibility, insurance) {
        // sex: male, female
        // risk: 0.0 ~
        // eligibility: yes, no
        // insurance: yes, no, unknown
        var result = "Default Explanation";

        // Math.round()

        // deal with it.

        // method 2: encapsulate into a function in  MyCalcuataion
        result = (
          <div>
            跟其他與你相似的人比較，如果你接受篩檢，未來 6 年每 1000人中就有 {MyCalculation.calculateSavedByScreeningFromRisk(risk)} 人免去因肺癌而死。
          </div>
        );

        // method 1: calculate directly
        /*
        result = (
          <div>
            Compared to other people like you, there will be{" "}
            {Math.round(Math.round(risk * 10) - Math.round(risk * 10 * 0.8)) +
              " "}
            fewer deaths out of 1000 in the next 6 years if you get screened.
          </div>
        );
        */

        return result;
      },
      getBenefitTitle(sex, risk, eligibility, insurance) {
        // sex: male, female
        // risk: 0.0 ~
        // eligibility: yes, no
        // insurance: yes, no, unknown

        // we only need risk

        var result = "預設福利";

        result = "在 1000 個人中，有 " + 
          MyCalculation.calculateSavedByScreeningFromRisk(risk)  +
          " 人";

        return result;
      },
      reflection_questions: {
        formPersonalBenefit: {
          label: "",
          placeholder: "",
          options: [
            {
              label:
                "是的，我從篩檢中獲得的益處讓我認為接受篩檢是正確的選擇。",
              value:
                "Yes. My benefit from screening is enough to justify getting screened."
            },
            {
              label:
                "也許，我從篩檢中獲得的益處讓我認為接受篩檢是大概是正確的選擇。",
              value:
                "Maybe. My benefit from screening is probably enough to justify getting screened but I am not sure."
            },
            {
              label:
                "也許不會，我從篩檢中獲得的益處較小讓我不確定接受篩檢是不是個正確的選擇。",
              value:
                "Maybe not. My benefit from screening is small and I'm not sure it justifies getting screened."
            },
            {
              label:
                "不會，我從篩檢中獲得的益處太小讓我覺得接受篩檢不算是正確的選擇。",
              value:
                "No. My benefit from screening is too small to justify getting screened."
            }
          ]
        },
        formRecommended: {
          label: "我被推薦:",
          placeholder: "",
          options: [
            {
              label: "接受篩檢",
              value: "Recommended to be screened"
            },
            {
              label: "不要接受篩檢",
              value: "Not recommended to be screened"
            }
          ]
        },
        formRisk: {
          label: "我的風險是",
          placeholder: "1%"
        },
        formReason: {
          label: "我的原因是:",
          placeholder: ""
        }
      }
    },
    coveragePage: {
      banner_image_source: "/image/bannerImage_coveragePage.png",
      banner_title: "肺癌篩檢(檢查)的保險給付範圍",
      title:
        "點選適用於您的選項，查看您的健康保險是否涵蓋肺癌篩檢(檢查)。",
      //content: "The following information is from the American Lung Association.",
      condition_list: [
        {
          title: "僱主贊助的保險計劃",
          introtext:
            "這是通過您的雇主、工會或其他專業協會為您提供的保險。如果您符合下列的條件，您有資格參加最初的肺癌篩檢：",
          criteria_list: [
            "年齡在50至80歲之間",
            <div>
              有20包年的吸菸史 (點選&nbsp;
              <Link
                to={"/TraditionalChinese/pack-year-calculator"}
                style={{ width: "100%", textDecoration: "none" }}
                data-on="click"
                data-event-category="CoveragePage"
                data-event-action={"/TraditionalChinese/pack-year-calculator"}
              >
                連結&nbsp;
              </Link>
              算出你的包年)
            </div>,
            "在過去的15年裡吸菸"
          ],

          note:
            "如果您的計劃是平價醫療法案 (Affordable Care Act) 中允許的情況，它不需要涵蓋肺癌篩檢。請檢查您的保險內容，並與您的保險公司核實確認。",
          question_answer_list: [
            {
              question: "需要分擔費用嗎？",
              answer:
                "不。如果您向您的醫療供給者尋求篩檢，您不應該被收取掛號費(定額手續費)、共同承擔額或自付額。"
            },
            {
              question: "有其他的費用嗎?",
              answer:
                "可能有。請務必諮詢您的保險公司，確認他們為該這個篩檢手續提供哪些給付。"
            }
          ]
        },
        {
          title: "國家保險市場計劃 (State insurance marketplace plan)",
          introtext:
            "這是您通過 healthcare.gov 或您州設置的市場/交換網站註冊的計劃。您有資格參加最初的肺癌篩檢，如果您符合下列的條件:",
          criteria_list: [
            "are between 50 and 80 years old",
            <div>
              有20包年的吸菸史 (點選&nbsp;
              <Link
                to={"/TraditionalChinese/pack-year-calculator"}
                style={{ width: "100%", textDecoration: "none" }}
                data-on="click"
                data-event-category="CoveragePage"
                data-event-action={"/TraditionalChinese/pack-year-calculator"}
              >
                連結
              </Link>{" "}
              算出你的包年)
            </div>,
            "在過去的15年裡吸菸"
          ],
          question_answer_list: [
            {
              question: "需要分擔費用嗎？",
              answer:
                "不。如果您向您的醫療供給者尋求篩檢，您不應該被收取掛號費(定額手續費)、共同承擔額或自付額。"
            },
            {
              question: "有其他的費用嗎?",
              answer:
                "可能有。請務必諮詢您的保險公司，確認他們為該這個篩檢手續提供哪些給付。"
            }
          ]
        },
        {
          title: "聯邦老人醫療保險 (Medicare)",
          introtext:
            "您有資格參加最初的肺癌篩檢，如果您符合下列的條件:",
          criteria_list: [
            "年齡在50至77歲之間",
            <div>
              有20包年的吸菸史 (點選&nbsp;
              <Link
                to={"/TraditionalChinese/pack-year-calculator"}
                style={{ width: "100%", textDecoration: "none" }}
                data-on="click"
                data-event-category="CoveragePage"
                data-event-action={"/TraditionalChinese/pack-year-calculator"}
              >
                連結
              </Link>{" "}
              算出你的包年)
            </div>,
            "在過去的15年裡吸菸",
            "沒有肺癌的跡象或癥狀（例如疼痛、體重減輕、持續咳嗽）"
          ],
          question_answer_list: [
            {
              question: "需要分擔費用嗎？",
              answer:
                "不。如果您向您的醫療供給者尋求篩檢，您不應該被收取掛號費(定額手續費)、共同承擔額或自付額。"
            },
            {
              question: "有其他的費用嗎?",
              answer:
                "可能有。請務必諮詢您的保險公司，確認他們為該這個篩檢手續提供哪些給付。"
            }
          ]
        },
        {
          title: "聯邦醫療保險 (Medicaid)",
          introtext:
            "聯邦醫療保險中肺癌篩檢的覆蓋面因州而異。我們建議您聯繫您的醫療服務提供者，並檢查您的計劃是否涵蓋肺癌篩檢。",

          criteria_list: [],
          note: "",
          question_answer_list: []
        },
        {
          title: "個人保險計劃",
          introtext:
            "這是您直接從保險公司購買的保險。如果您符合下列的條件，您有資格參加最初的肺癌篩檢:",
          criteria_list: [
            "年齡在50至80歲之間",
            <div>
              有20包年的吸菸史 (點選&nbsp;
              <Link
                to={"/TraditionalChinese/pack-year-calculator"}
                style={{ width: "100%", textDecoration: "none" }}
                data-on="click"
                data-event-category="CoveragePage"
                data-event-action={"/TraditionalChinese/pack-year-calculator"}
              >
                連結
              </Link>{" "}
              算出你的包年)
            </div>,
            "在過去的15年裡吸菸"
          ],

          note:
            "如果您的計劃是平價醫療法案 (Affordable Care Act) 中允許的情況，它不需要涵蓋肺癌篩檢。請檢查您的保險內容，並與您的保險公司核實確認。",
          question_answer_list: [
            {
              question: "需要分擔費用嗎？",
              answer:
                "不。如果您向您的醫療供給者尋求篩檢，您不應該被收取掛號費(定額手續費)、共同承擔額或自付額。"
            },
            {
              question: "有其他的費用嗎?",
              answer:
                "可能有。請務必諮詢您的保險公司，確認他們為該這個篩檢手續提供哪些給付。"
            }
          ]
        }
      ],
      citation_text: (
        <div>
          <p>
          這些資訊來自美國肺臟協會&nbsp;
            <a
              target="_blank"
              href="https://www.lung.org/assets/documents/lung-health/lung-cancer-insurance-chart.pdf"
              rel="noopener noreferrer"
            >
              美國肺臟協會
            </a>
            .
          </p>
        </div>
      )
    },
    savedPage: {
      title: "以下是您保存的資訊",
      item_unit: "片段",
      print_button_label: "列印"
    },
    publicationPage: {
      title: "研究出版品",
      publication_list: [
        {
          category: "journal",
          text: (
            <span>
              Yan Kwan Lau, Harihar Bhattarai, Tanner J Caverly, Pei-Yao Hung, 
              Evelyn Jimenez-Mendoza, Minal R Patel, Michele L Coté, 
              Douglas A Arenberg, Rafael Meza (2021).
              Lung Cancer Screening Knowledge, Perceptions, and Decision Making 
              Among African Americans in Detroit, Michigan.{" "}
              <i>American Journal of Preventive Medicine (AJPM)</i>, Volume
              60, Issue 1, Pages e1-e8
            </span>
          ),
          year: 2021,
          description:
            "我們在密西根州底特律東部測試了 shouldiscreen.com 是否有助於提高人們對肺癌風險和肺癌篩檢(檢查)的認識，並減少他們的決策衝突。",
          links: [
            {
              label: "Link",
              link: "https://pubmed.ncbi.nlm.nih.gov/33341184/"
            }
          ]
        },
        {
          category: "conference",
          text: (
            <span>
              Pei-Yao Hung, Yan Kwan Lau, Mark S. Ackerman, Rafael Meza
              (2019). Designing a Web-based Decision Aid for Individuals to
              Consider Lung Cancer Screening.{" "}
              <i>
                13th EAI International Conference on Pervasive Computing
                Technologies for Healthcare (PervasiveHealth)
              </i>
              , Trento, Italy, May 20-23. pp 51-60
            </span>
          ),
          year: 2019,
          description:
            "我們舉辦了參與式設計工作坊和焦點團體訪談，為決策輔助工具提供設計建議用來指引 shouldiscreen.com 的發展。",
          links: [
            {
              label: "Link",
              link: "https://doi.org/10.1145/3329189.3329210"
            }
          ]
        },
        {
          category: "journal",
          text: (
            <span>
              Yan Kwan Lau, Tanner J. Caverly, Pianpian Cao, Sarah T. Cherng,
              Mindy West, Charles Gaber, Douglas Arenberg, Rafael Meza (2015).
              Evaluation of a Personalized, Web-Based Decision Aid for Lung
              Cancer Screening.{" "}
              <i>American Journal of Preventive Medicine (AJPM)</i>, Volume
              49, Issue 6, Pages e125–e129
            </span>
          ),
          year: 2015,
          description:
            "我們測試了 shouldiscreen.com 是否有助於提高人們對肺癌風險和肺癌篩檢的認識，並減少他們的決策衝突。",
          links: [
            {
              label: "Link",
              link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5544524/"
            }
          ]
        },
        {
          category: "journal",
          text: (
            <span>
              Yan Kwan Lau, Tanner J Caverly, Sarah T Cherng, Pianpian Cao,
              Mindy West, Douglas Arenberg, and Rafael Meza (2014).
              Development and Validation of a Personalized, Web-Based Decision
              Aid for Lung Cancer Screening Using Mixed Methods: A Study
              Protocol. <i>JMIR Research Protocol</i>
            </span>
          ),
          year: 2014,
          description:
            "這是一個規程，描述了 shouldiscreen.com 各個階段的開發和研究計劃。",
          links: [
            {
              label: "Link",
              link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4376198/"
            }
          ]
        }
      ]
    },
    teamPage: {
      title: "Should I Screen 團隊",

      investigator_title: "計畫主持人",

      investigator_list: [
        {
          name: "Rafael Meza, PhD",
          affiliation: [
            "密西根大學公共衛生學院流行病學系"
          ],
          link: "https://sph.umich.edu/faculty-profiles/meza-rafael.html"
        },
        {
          name: "Douglas Arenberg, MD",
          affiliation: [
            "密西根大學內科醫學系"
          ],
          link: "https://uofmhealth.org/profile/286/douglas-allen-arenberg-md"
        },
        {
          name: "劉妍君 (Lisa Lau) 博士，公共衛生碩士",
          affiliation: [
            "密西根大學公共衛生學院流行病學系"
          ],
          link: ""
        },
        {
          name: "Tanner Caverly, MD, MPH ",
          affiliation: [
            "安娜堡醫療保健系統退伍軍人事務部臨床管理研究中心；密西根大學內科醫學系"
          ],
          link: "http://medicine.umich.edu/dept/lhs/tanner-caverly-md-mph"
        }
      ],

      research_development_title: "研發團隊",

      research_development_list: [
        {
          name: "曹翩翩 (Pianpian Cao), 博士，公共衛生碩士",
          affiliation: [
            "密西根大學公共衛生學院流行病學系"
          ],
          link: "https://sph.umich.edu/epid/phdstudents/cao_pianpian.html"
        },
        {
          name: "程慈如 (Sarah Cherng) 博士，公共衛生碩士",
          affiliation: [
            "密西根大學公共衛生學院流行病學系"
          ],
          link: "http://www.sarahcherng.com/"
        },
        {
          name: "洪培堯 (Pei-Yao Hung) 博士，資訊科學碩士",
          affiliation: ["密西根大學資訊學院"],
          link: "https://peiyaoh.github.io/"
        },
        {
          name: "Mindy West, MPH",
          affiliation: [
            "密西根大學公共衛生學院流行病學系"
          ],
          link: ""
        }
      ],

      press_title: "新聞發佈",

      press_resources_list: [
        {
          link:
            "https://www.healthcanal.com/cancers/lung-cancer/68111-tool-helps-patients-providers-decide-if-lung-cancer-screening-is-warranted.html",
          label:
            "Tool helps patients decide if lung cancer screening is warranted",
          source_label: "Health Canal"
        },
        {
          link:
            "http://www.reuters.com/article/us-health-screening-lung-cancer-idUSKCN0SF2LQ20151021#f3dQjQZuWa652jvR.97",
          label:
            "Web module helps patients decide about lung cancer screening",
          source_label: "Reuters"
        },
        {
          link:
            "https://www.nytimes.com/2015/05/12/health/on-medicare-and-assessing-the-value-of-lung-cancer-screening.html",
          label:
            "On Medicare and assessing the value of lung cancer screening",
          source_label: "New York Times"
        },
        {
          link:
            "https://news.umich.edu/tool-helps-patients-providers-decide-if-lung-cancer-screening-is-warranted/",
          label:
            "Tool helps patients, providers decide if lung cancer screening is warranted",
          source_label: "University of Michigan News"
         }
      ],
      founding_title: "資金來源",
      founding_content:
        "本工具的開發得到了密西根大學綜合癌症中心的 Elizabeth A Crary 基金（2014-2015 年）和密西根大學羅傑爾癌症中心的癌症控制和人口科學研究計劃：外展和健康差異補助金的支持。"
    },
    historyPage: {
      title: "shouldiscreen.com 的發展歷程",
      description: <Fragment>
        <p>Shouldiscreen.com 是一個肺癌篩檢(檢查)決策輔助工具，它根據現有文獻、多領域研究工作、健康專家的建議和用戶的反饋不斷設計、評估和重新設計。</p>
        <p>我們的目標是通過使本工具與當前對肺癌篩檢的理解保持同步並通過以人為本的方法來不斷改進它，以便人們可以輕鬆地增進對相關科學知識的了解和實踐。</p>
        <p>在這裡，我們概述了 shouldicreen.com 發展過程中的里程碑和更新列表。</p>
      </Fragment>,
      eventList:[
        // marker_color:  color from bulma.css: is-primary, is-link, is-info, is-success, is-warning, is-danger
        // markder_type: "", is-image, is-icon
        
        {
          label: "September 2017",
          description: "添加西班牙語內容並啟用雙語支援。",
          timestamp: "2017-09-01T00:00:00",
          marker_color: "is-danger",
          marker_type: "is-icon",
          // fontawesome
          marker_icon_type: "fa fa-flag"
        },
        {
          label: "July 2018",
          description: <Fragment>根據參與式設計和焦點團體訪談的觀察和回饋，新增了關於保險給付的內容 (參閱 <a
            target="_blank"
            href="https://dl.acm.org/doi/10.1145/3329189.3329210"
            rel="noopener noreferrer"
          >
            連結
          </a>)。</Fragment>,
          timestamp: "2018-07-01T00:00:00",
          marker_color: "is-link"
        },
        {
          label: "September 2018",
          description: <Fragment>添加了“留存“功能，供人們在臨床上跟醫生進行共享決策的對話時能收集相關內容。</Fragment>,
          timestamp: "2018-09-01T00:00:00",
          marker_color: "is-link"
        },
        {
          label: "September 2018",
          description: <Fragment>添加了一個彈出式選單，供人們提供有關內容的回饋（實用/不實用)。</Fragment>,
          timestamp: "2018-09-15T00:00:00",
          marker_color: "is-primary"
        },
        {
          label: "December 2019",
          description: <Fragment>根據參與式設計和焦點團體訪談的觀察，添加了問題導向的導航選單。 (參閱 <a
            target="_blank"
            href="https://dl.acm.org/doi/10.1145/3329189.3329210"
            rel="noopener noreferrer"
          >
            連結
          </a>).</Fragment>,
          timestamp: "2019-12-01T00:00:00",
          marker_color: "is-link"
        },
        {
          label: "May 2019",
          description: <Fragment>在 <i>13th EAI International Conference on Pervasive Computing Technologies for Healthcare</i> (PervasiveHealth 2019) 年度會議上發表我們為了 shouldiscreen.com 所進行的參與式設計和焦點團體訪談的研究成果。(參閱 <a
            target="_blank"
            href="https://dl.acm.org/doi/10.1145/3329189.3329210"
            rel="noopener noreferrer"
          >
            連結
          </a>).</Fragment>,
          timestamp: "2019-05-21T00:00:00",
          marker_color: "is-warning"
        },
        {
          label: "December 2019",
          description: <Fragment>更新了聯邦老人醫療保險（Medicare）的篩選資格標準。</Fragment>,
          timestamp: "2019-12-12T00:00:00",
          marker_color: "is-primary"
        },
        {
          label: "January 2020",
          description: <Fragment>支援基於肺癌風險計算結果以及生活史資料的客製化資訊。</Fragment>,
          timestamp: "2020-01-01T00:00:00",
          marker_color: "is-primary"
        },
        {
          label: "August 2020",
          description: <Fragment>添加了多個視覺化呈現的選項，以幫助人們了解篩檢的益處和風險。</Fragment>,
          timestamp: "2020-08-22T00:00:00",
          marker_color: "is-primary"
        },
        {
          label: "November 2020",
          description: <Fragment>修訂關於假警訊造成危害的內容以反映美國預防服務工作小組（USPSTF） 證據審查 (2020) 中有關使用 LungRADS 資訊的發現。(參閱 <a
            target="_blank"
            href="https://www.uspreventiveservicestaskforce.org/uspstf/document/draft-evidence-review/lung-cancer-screening1"
            rel="noopener noreferrer"
          >
            連結
          </a>).</Fragment>,
          timestamp: "2020-11-21T00:00:00",
          marker_color: "is-primary"
        },
        {
          label: "January 2022",
          description: "添加中文內容並啟用多語支援。",
          timestamp: "2022-01-29T00:00:00",
          marker_color: "is-danger",
          marker_type: "is-icon",
          // fontawesome
          marker_icon_type: "fa fa-flag"
        },
        /*
        {
          label: "February 2016",
          description: "Timeline content - Can include any HTML element",
          timestamp: "2016-02-01T00:00:00",
          marker_color: "is-warning",
          marker_type: "is-image",
          marker_image_size: "is-32x32",
          marker_image_source: "https://bulma.io/images/placeholders/32x32.png"
        },
        */
        
      ]
    },
    noPage: {
      title: "我應該接受篩檢(檢查)嗎？: 未找到",
      not_found_message:
        "您不小心到達了這個頁面，或者我們的網站有問題。 請點擊以下連結返回首頁。",
      take_me_home_label: "前往首頁。",
      take_me_home_link: "/TraditionalChinese/home"
    },
    footerComponent: {
      development_team_content: (
        <p>
          本網站是由 &nbsp;
          <Link
            to={"/TraditionalChinese/development-team"}
            className="my-link"
            data-on="click"
            data-event-category="Navigation"
            data-event-action={"team"}
          >
            密西根大學的研究團隊
          </Link>
          &nbsp; 使用 Tammemägi et al. (2013) 等人開發的風險預測模型來開發。&nbsp;
          <a
            target="_blank"
            href="https://www.nejm.org/doi/full/10.1056/NEJMoa1211776"
            rel="noopener noreferrer"
            className="my-link"
          >
            Selection criteria for lung-cancer screening
          </a>
          .&nbsp;<em>New England Journal of Medicine</em>, 368(8): 728-736,
          2013.
        </p>
      ),
      basis_content: (
        <p>
          請參閱{" "}
          <Link
            to={"/TraditionalChinese/publications"}
            data-on="click"
            data-event-category="Navigation"
            data-event-action={"publications"}
            className="my-link"
          >
            出版物
          </Link>{" "}
          以獲取有關此決策輔助工具的設計，開發和驗證的研究論文 (同行審議文章)。
        </p>
      ),

      feedback_content: (
        <p>
           您的回饋對我們很重要，也很有價值。請&nbsp;
          <Link to={"/TraditionalChinese/feedback"} className="my-link">填寫此調查</Link>
          &nbsp;以幫助我們改善這個網站。&nbsp;
        </p>
      ),

      disclaimer_content: (
        <p>
          <Link to={"/TraditionalChinese/disclaimer"} className="my-link">免責聲明</Link>。 &nbsp;
          <em>
          本網站的書面內容在&nbsp; 
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://creativecommons.org/licenses/by-nc-sa/4.0/"
              className="my-link"
            >
              CC BY-NC-SA 4.0
            </a>
            下共享。
          </em>
          本網站上的所有圖片均受版權保護，除非頁面上另有說明，否則保留所有權利。
        </p>
      )
    },
    lastUpdateComponent: {
      message_head: "本網站的最新更新時間為 ",
      pointer_to_history: <Fragment>
        查閱<Link
                to={"/TraditionalChinese/development-history"}
                data-on="click"
                data-event-category="UpdateComponent"
                data-event-action={"/TraditionalChinese/development-history"}
                className="my-link"
              >
                更新紀錄
              </Link>。
      </Fragment>
    },
    headerComponent: {
      title: "關於肺癌跟篩檢(檢查)",
      menu_title: "選單",
      route_in_menu_limit: 8, //9
      route_order_list: [
        {
          route: "/TraditionalChinese/home",
          label: "首頁",
          title: "我應該接受篩檢嗎? 肺癌篩檢決策輔助",
          page: "HomePage",
          description: "肺癌篩檢使用低劑量計算機斷層掃描 (LDCT) 在早期發現肺癌。 以下是醫生提供的信息，可幫助您做出決定。",
          hashTagList: ["shouldiscreen", "肺癌", "篩檢", "健康"]
        },
        {
          route: "/TraditionalChinese/how-is-screening-done",
          label: "什麼是肺癌篩檢?",
          title: "什麼是肺癌篩檢?",
          page: "HowPage",
          hashTagList: ["shouldiscreen", "肺癌", "篩檢", "健康"]
        },
        {
          route: "/TraditionalChinese/coverage",
          label: "保險給付",
          title: "肺癌篩檢的保險給付",
          page: "CoveragePage",
          hashTagList: ["shouldiscreen", "肺癌", "篩檢", "健康"]
        },
        {
          route: "/TraditionalChinese/benefits-and-harms-screening",
          label: "篩檢的利弊",
          title: "肺癌篩檢的利弊",
          page: "BenefitHarmPage",
          hashTagList: ["shouldiscreen", "肺癌", "篩檢", "健康"]
        },
        /*
        {
          route: "/TraditionalChinese/compare-with-other-screening-tests",
          label: "Compared to other tests",
          title:
            "Comparison of lung cancer screening to other types of common cancer screening",
          page: "ComparePage",
          hashTagList: ["shouldiscreen", "肺癌", "篩檢", "健康"]
        },
        */
        {
          route: "/TraditionalChinese/causes-of-lung-cancer",
          label: "肺癌的成因",
          title: "肺癌的成因",
          page: "CausePage",
          hashTagList: ["shouldiscreen", "肺癌", "篩檢", "健康"]
        },
        {
          route: "/TraditionalChinese/pack-year-calculator",
          label: "抽菸量(包年)計算",
          title: "計算抽菸量(包年)",
          page: "PackPage",
          hashTagList: ["shouldiscreen", "肺癌", "篩檢", "健康"]
        },
        {
          route: "/TraditionalChinese/risk-reduction-lung-cancer",
          label: "降低患肺癌的風險",
          title: "降低患肺癌的風險",
          page: "RiskPage",
          hashTagList: ["shouldiscreen", "肺癌", "篩檢", "健康"]
        },
        {
          route: "/TraditionalChinese/lung-cancer-risk-calculator",
          label: "估算我的肺癌風險",
          title: "估算我的肺癌風險",
          page: "CalculatePage",
          hashTagList: ["shouldiscreen", "肺癌", "篩檢", "健康"]
        },
        {
          route: "/TraditionalChinese/result",
          label: "結果",
          title: "我的肺癌風險",
          page: "ResultPage",
          hashTagList: ["shouldiscreen", "肺癌", "篩檢", "健康"]
        },
        {
          route: "/TraditionalChinese/disclaimer",
          label: "免責聲明",
          title: "免責聲明",
          page: "DisclaimerPage",
          hashTagList: ["shouldiscreen", "肺癌", "篩檢", "健康"]
        },
        {
          route: "/TraditionalChinese/development-team",
          label: "研發團隊",
          title: "研發團隊",
          page: "TeamPage",
          hashTagList: ["shouldiscreen", "肺癌", "篩檢", "健康"]
        },
        {
          route: "/TraditionalChinese/development-history",
          label: "研發歷程",
          title: "研發歷程",
          page: "HistoryPage",
          hashTagList: ["shouldiscreen", "肺癌", "篩檢", "健康"]
        },
        {
          route: "/TraditionalChinese/feedback",
          label: "意見回饋",
          title: "意見回饋",
          page: "FeedbackPage",
          hashTagList: ["shouldiscreen", "肺癌", "篩檢", "健康"]
        },
        {
          route: "/TraditionalChinese/saved",
          label: "保存",
          title: "保存的內容",
          page: "SavedPage",
          hashTagList: ["shouldiscreen", "肺癌", "篩檢", "健康"]
        },
        {
          route: "/TraditionalChinese/publications",
          label: "研究出版品",
          title: "研究出版品",
          page: "PublicationPage",
          hashTagList: ["shouldiscreen", "肺癌", "篩檢", "健康"]
        }
      ]
    },
    savedComponent: {
      saved_label: "Saved",
      saved_url: "/TraditionalChinese/saved"
    }
  }
}
